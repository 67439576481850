import { t } from "@lingui/macro";
import { Modal } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Configuration } from "types/type";

const isPassowrdExpired = (configuration: Configuration, push: (url: any) => Promise<boolean>) => {
  const now = dayjs();
  const passwordExpiresAt = configuration?.user?.password_expires_at;

  if (passwordExpiresAt && now.isAfter(dayjs(passwordExpiresAt))) {
    return Modal.info({
      type: "info",
      title: t`Your password has expired`,
      content: t`Please update your password to keep your account secure.`,
      onOk: () => push(`/settings/change-password`),
      okText: t`Change password`,
      cancelText: t`Close`,
      closable: true,
    });
  }
};

const useInitializeConfiguration = (token: string | null) => {
  const [configuration, setConfiguration] = useState<Configuration>();
  const { route, push } = useRouter();

  useEffect(() => {
    if (token) {
      axios
        .get(`/api/configurations`)
        .then((res) => {
          const _configuration = res.data;

          if (route !== "/settings/change-password") {
            isPassowrdExpired(_configuration, push);
          }

          setConfiguration(_configuration);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            axios.post("/api/logout", {}).then(() => {
              location.reload();
            });
          }
        });
    }
    // Only trigger after refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return configuration;
};

export default useInitializeConfiguration;
