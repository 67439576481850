import useSWR, { BareFetcher } from "swr";
import { Account } from "types/type";
import { useState } from "react";
import { debounce } from "lodash";

const DELAY = 500;

export const useFetchAccountsWithPermission = ({
  baseUrl,
  permission,
  fetcher,
  pageSize = 20,
  search,
}: {
  baseUrl: string;
  permission: string;
  fetcher: BareFetcher<any>;
  pageSize?: number;
  search?: string;
}) => {
  const params = new URLSearchParams({
    page_size: String(pageSize),
    ordering: "name",
    ...(search && { search }),
    ...(permission && { explicit_permissions: permission }),
  });

  const fetchUrl = baseUrl && `${baseUrl}?${params.toString()}`;

  const { data, error } = useSWR<Account[]>(fetchUrl, { fetcher, revalidateIfStale: false });

  return { data, loading: !data && !error };
};

export const useSearchAccounts = (permission?: string, fetcher?: BareFetcher<any>) => {
  const [search, setSearch] = useState("");

  const { data, loading } = useFetchAccountsWithPermission({
    baseUrl: search && "accounts/",
    permission,
    search,
    fetcher,
  });

  const onSearch = debounce(setSearch, DELAY);

  return {
    accounts: data,
    onSearch,
    loading: search && loading,
  };
};
