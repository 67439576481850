import React from "react";

import { t } from "@lingui/macro";
import { useRouter } from "next/router";
import styled from "styled-components";

import GSMTasksButton from "components/uikit/button";
import NotFoundIcon from "icons/not-found";
import { httpStatusCodes } from "utils/status-codes-utils";
import { Space } from "antd";

const errorCode = (code) => {
  switch (true) {
    case code === httpStatusCodes.NOT_FOUND:
      return {
        icon: <NotFoundIcon />,
        header: "Page Not Found",
        msg: "The page you're looking for could not be found.",
      };
    case code === httpStatusCodes.UNAUTHORIZED:
      return {
        icon: <NotFoundIcon />,
        header: "Unauthorized",
        msg: "You don't have the necessary permission to view this page, Please contact a manager",
      };
    // add more error cases...
    default:
      return { icon: null, header: "An Error Occurred", msg: "Could not handle operation" };
  }
};

const ErrorPage = ({ code }: { code: number; message?: string }) => {
  const pageInfo = errorCode(code);
  const router = useRouter();

  return (
    <Container>
      {pageInfo.icon && pageInfo.icon}
      {pageInfo.header && <h1 className={"headings"}>{pageInfo.header}</h1>}
      {pageInfo.msg && <p className={"description"}>{pageInfo.msg}</p>}

      <Space>
        {[httpStatusCodes.NOT_FOUND, httpStatusCodes.UNAUTHORIZED].includes(code) && (
          <GSMTasksButton
            size="large"
            onClick={() => router.push("/dashboard")}
            type="primary"
            label={t`Go to dashboard`}
          />
        )}
        {httpStatusCodes.UNAUTHORIZED === code && (
          <GSMTasksButton
            size="large"
            onClick={() => router.push("/login?redirect=false")}
            type="default"
            label={t`Login`}
          />
        )}
      </Space>
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 52px);
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  .headings {
    line-height: 0;
    margin-top: 36px;
    color: #2a2f39;
  }

  .description {
    text-align: center;
    max-width: 330px;
    color: #2a2f39;
    margin-top: 20px;
  }
`;

export default ErrorPage;
