import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import axios from "axios";
import { get, isEmpty } from "lodash";
import dayjs from "dayjs";

const DELAY = 60; // in minutes;
const appBuildId = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;

const DeploymentManager = () => {
  const [nextCheck, setNextCheck] = useState<string>();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = async () => {
      const now = dayjs();

      if (now.isBefore(nextCheck)) {
        return;
      }

      try {
        setNextCheck(now.add(DELAY, "minutes").format());
        const response = await axios.get("/api/build-id");
        const serverBuildId = get(response?.data, "buildId");

        if (!isEmpty(serverBuildId) && !isEmpty(serverBuildId) && serverBuildId !== appBuildId) {
          router.reload();
        }
      } catch (error) {
        // ignore error
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [nextCheck]);

  return null;
};

export default DeploymentManager;
