import { components } from "./openapi";

export type Account = components["schemas"]["Account"];
export type AccountRole = components["schemas"]["AccountRole"];
export type Configuration = components["schemas"]["ConfigurationSerializerV2"];
export type Contact = components["schemas"]["ContactAddress"];
export type EmailNotification = components["schemas"]["Email"];
export type LastTimeLocations = components["schemas"]["TimeLocation"];
export type MetaField = components["schemas"]["Metafield"];
export type Optimizations = components["schemas"]["RouteOptimizationSerializerV2"];
export type RouteOptimizationResultRoute = components["schemas"]["RouteOptimizationResultRoute"];
export type Order = components["schemas"]["OrderSerializerV2"];
export type Recurrence = components["schemas"]["Recurrence"];
export type PushNotification = components["schemas"]["PushNotification"];
export type Review = components["schemas"]["Review"];
export type SMSNotification = components["schemas"]["SMS"];
export type Task = components["schemas"]["Task"];
export type TaskCommands = components["schemas"]["TaskCommand"];
export type TaskEvent = components["schemas"]["TaskEvent"];
export type TaskForm = components["schemas"]["TaskForm"];
export type Tracker = components["schemas"]["Tracker"];
export type User = components["schemas"]["ReadableUser"];
export type Address = components["schemas"]["NestedAddress"];
export type TaskCategoryEnum = components["schemas"]["TaskCategoryEnum"];
export type Route = components["schemas"]["Route"];
export type ClientRole = components["schemas"]["ClientRole"];
export type Document = components["schemas"]["Document"];

// enums
export enum TASK_COMMAND_ACTIONS {
  ACCEPT = "accept",
  ACTIVATE = "activate",
  ASSIGN = "assign",
  ASSIGNEE_AWAY = "assignee_away",
  ASSIGNEE_NEAR = "assignee_near",
  CANCEL = "cancel",
  COMPLETE = "complete",
  FAIL = "fail",
  REASSIGN = "reassign",
  REJECT = "reject",
  TRANSIT = "transit",
  UNACCEPT = "unaccept",
  UNASSIGN = "unassign",
  UPDATE = "update",
}

export enum ROUTE_ACTION {
  ACTIVATE = "activate",
  DRAFT = "draft",
  ARCHIVE = "archive",
}

export interface LatLng {
  lat: number;
  lng: number;
}
