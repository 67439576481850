import { t } from "@lingui/macro";
import { Tooltip } from "antd";
import { get } from "lodash";

import { DEFAULT_UNMANAGED_TASKS_STATES } from "components/dashboard/hooks/tasks";
import { setLatLngPrecision } from "components/map/utils";
import { handleStateChange } from "components/task/change-state";
import { useAppContext } from "contexts/app";
import { TASK_COMMAND_ACTIONS } from "types/type";

const MAX_NUMBER_OF_TASKS_FOR_BULK_NOTIFICATIONS = 50;

export const taskActions = (
  nTasks,
  isMarker,
  handler,
  canChangeAccounts,
  isAssigned,
  isCompleted,
  disableMoveTasks
) => {
  const disableReordering = disableMoveTasks || isCompleted;

  return [
    {
      ...(isMarker && {
        key: 0,
        label: t`Connect`,
        action: () => handler.handleConnecting(),
      }),
    },
    {
      key: 1,
      label: t`Task Info`,
      action: () => handler.onOpenTaskInfo(),
    },
    {
      key: 2,
      label: t`Edit Task`,
      action: () => handler.handleEditTask(),
      disabled: isCompleted,
    },
    {
      key: 3,
      label: t`Center in map`,
      action: () => handler.handleCenterMap(),
    },
    {
      key: 4,
      label: t`Clone Task`,
      action: () => handler.handleCloneTask(),
    },
    {
      key: 5,
      label: t`Notify Assignee`,
      action: () => handler.onOpenNotification("assignee"),
      disabled: !isAssigned,
    },
    {
      key: 6,
      label: t`Notify Contact`,
      action: () => handler.onOpenNotification("contact"),
    },
    {
      key: 7,
      label: t`Notify Orderer`,
      action: () => handler.onOpenNotification("orderer"),
    },
    {
      key: 8,
      label: t`Change State`,
      action: () => handler.onOpenChangeState(),
      disabled: isCompleted,
    },
    {
      key: 9,
      label: (
        <Tooltip title={disableReordering && t`Re-ordering of tasks is not permitted`}>
          {t`Move` + ` (${nTasks}) ` + t`tasks`}
        </Tooltip>
      ),
      action: () => handler.onOpenChangeAssignee(),
      disabled: disableReordering,
    },
    {
      key: 10,
      label: t`Complete task`,
      action: () => handler.onHandleChangeState(TASK_COMMAND_ACTIONS.COMPLETE),
      confirm: true,
      disabled: !isAssigned || isCompleted,
    },
    {
      key: 11,
      label: t`Cancel Task`,
      action: () => handler.onHandleChangeState(TASK_COMMAND_ACTIONS.CANCEL),
      confirm: true,
      disabled: isCompleted,
    },

    {
      key: 12,
      label: t`Clear` + " " + t`selected task`,
      action: () => handler.onClearSelectedTasks(),
    },
    {
      key: 13,
      label: t`Change Account`,
      disabled: !canChangeAccounts,
      action: () => handler.onOpenChangeAccounts(),
    },
  ];
};

const multipleSelectedActions = (nTasks, handler, isUnAssigned, isCompleted) => {
  return [
    {
      key: 1,
      label: t`Change State`,
      action: () => handler.onOpenChangeState(),
      disabled: isCompleted,
    },
    {
      key: 2,
      label: t`Move` + ` (${nTasks}) ` + t`tasks`,
      action: () => handler.onOpenChangeAssignee(),
      disabled: isCompleted,
    },
    {
      key: 3,
      label: t`Optimize worker route`,
      action: () => handler.onOpenOptimizeWorkerRoutes(),
      disabled: isUnAssigned,
    },
    {
      key: 4,
      label: t`Notify Assignees`,
      action: () => handler.onOpenBulkNotify("assignee"),
      disabled: isUnAssigned || nTasks > MAX_NUMBER_OF_TASKS_FOR_BULK_NOTIFICATIONS,
    },
    {
      key: 5,
      label: t`Notify Contacts`,
      action: () => {
        handler.onOpenBulkNotify("contact");
      },
      disabled: nTasks > MAX_NUMBER_OF_TASKS_FOR_BULK_NOTIFICATIONS,
    },
    {
      key: 6,
      label: t`Notify Orderers`,
      action: () => {
        handler.onOpenBulkNotify("orderer");
      },
      disabled: nTasks > MAX_NUMBER_OF_TASKS_FOR_BULK_NOTIFICATIONS,
    },
    {
      key: 7,
      label: t`Clear` + ` (${nTasks}) ` + t`selected tasks`,
      action: () => handler.onClearSelectedTasks(),
    },
  ];
};

const useTaskContextMenu = (
  isMarker,
  task,
  tasks,
  clearTasks = null,
  editTask,
  onSetEditAbleTask,
  mutateTasks,
  dispatchActions,
  onSetTasksCenter,
  handleCloneTasks,
  taskCommands,
  taskGroups,
  setIsConnecting = null
) => {
  const { appData, configuration } = useAppContext();
  const isFirst = get(configuration, "features.task_create_position_first");
  const canChangeAccount = get(configuration, "features.change_task_account");

  if (!task) {
    return [];
  }

  const actions = {
    onOpenChangeAccounts: () => {
      dispatchActions({ type: "TASK", value: task });
      dispatchActions({ type: "CHANGE_ACCOUNT" });
    },
    handleEditTask: () => {
      onSetEditAbleTask(task);
      editTask();
    },
    handleCloneTask: () => {
      handleCloneTasks(task);
    },

    // toggle modals visible or !visible
    onClearSelectedTasks: () => {
      clearTasks && clearTasks([]);
    },
    onOpenChangeAssignee: () => {
      dispatchActions({ type: "TASKS", value: tasks.length === 0 ? [task] : tasks });
      dispatchActions({ type: "CHANGE_ASSIGNEE" });
    },
    onOpenOptimizeWorkerRoutes: () => {
      dispatchActions({ type: "OPTIMIZE", routeId: null });
      dispatchActions({ type: "TASKS", value: { tasks: tasks.length === 0 ? [task] : tasks } });
    },
    onOpenChangeState: () => {
      dispatchActions({ type: "TASKS", value: tasks.length === 0 ? [task] : tasks });
      dispatchActions({ type: "CHANGE_STATE" });
    },
    onHandleChangeState: (action) => {
      handleStateChange(isFirst, [task], taskGroups, taskCommands, action);
    },
    onOpenNotification: (action: string) => {
      dispatchActions({ type: "TASK", value: task });
      dispatchActions({ type: "NOTIFY", notify: action });
    },
    onOpenBulkNotify: (action: string) => {
      dispatchActions({ type: "BULK_NOTIFY", bulkNotify: action });
    },
    onOpenTaskInfo: () => {
      dispatchActions({ type: "TASK", value: task });
      dispatchActions({ type: "TASK_INFO" });
    },
    handleCenterMap: () => {
      if (task?.address?.location?.coordinates) {
        onSetTasksCenter(
          setLatLngPrecision({
            lat: task.address.location.coordinates[1],
            lng: task.address.location.coordinates[0],
          })
        );
        appData.mapConfig.setBounds(null);
      }
    },
    handleConnecting: () => {
      if (setIsConnecting) {
        const coord = task.address.location.coordinates;
        setIsConnecting({ type: "ACTIVE" });
        setIsConnecting({
          type: "SOURCE",
          value: {
            coordinates: { lat: coord[1], lng: coord[0] },
            taskId: task.id,
            workerId: task.assignee,
          },
        });
      }
    },
  };

  let ctxMenu;
  if (tasks.length > 1) {
    const isUnAssigned = tasks && tasks[0].assignee === null;
    const isCompletedOrCancelled = tasks && DEFAULT_UNMANAGED_TASKS_STATES.includes(tasks[0].state);
    ctxMenu = multipleSelectedActions(tasks.length, actions, isUnAssigned, isCompletedOrCancelled);
  } else {
    const isCompletedOrCancelled = DEFAULT_UNMANAGED_TASKS_STATES.includes(task?.state);
    ctxMenu = taskActions(
      tasks.length,
      isMarker,
      actions,
      canChangeAccount,
      !!task.assignee,
      isCompletedOrCancelled,
      appData.disableTasksReordering
    );
  }

  return ctxMenu;
};

export default useTaskContextMenu;
